<template>
  <div class="deposits-setting">
    <div class="header">
      <div class="titre">
        <h3>Gestion des Agences</h3>
      </div>
      <div class="button">
        <b-button
          size="sm"
          variant="light"
          v-if="sortDesc"
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-up" />
        </b-button>
        <b-button
          size="sm"
          variant="light"
          v-else
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-down" />
        </b-button>
        <b-button
          size="sm"
          variant="success"
          v-b-modal.addDepotsModal
          class="searchIcon"
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter une agence
        </b-button>
      </div>
    </div>
    <div v-if="!initLoading && loading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <hr />
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <b-list-group v-else>
      <b-list-group-item
        href="#"
        :class="deposit.visible ? '' : 'collapsed'"
        :aria-controls="'collapse-' + index"
        @click="deposit.visible = !deposit.visible"
        v-for="(deposit, index) in deposits"
        :key="index"
        :value="index.id"
      >
        <div class="content">
          <div class="gras">
            {{ deposit.name }}
          </div>

          <div class="right">
            <div class="actions">
              <b-button
                size="sm"
                variant="primary"
                title="Modifier"
                @click.prevent.stop="hundleUpdateClick(deposit)"
              >
                <font-awesome-icon class icon="pencil-alt" />
              </b-button>

              <b-button
                size="sm"
                title="Supprimer"
                variant="danger"
                @click.prevent.stop="hundleDeleteClick(deposit)"
              >
                <font-awesome-icon class icon="trash-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="aucun-agence"
        v-if="(!deposits || !deposits.length) && !initLoading"
      >
        Aucun Agence</b-list-group-item
      >
    </b-list-group>

    <!-- Add Deposit Modal -->
    <b-modal
      no-close-on-backdrop
      id="addDepotsModal"
      ref="addDepotsModal"
      title="Ajouter une agence"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="handleSubmit">
        <b-form-group label="Nom *" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="depositToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Code Postal *" label-for="Code Postal-input">
          <b-form-input
            type="number"
            id="Code Postal-input"
            v-model="depositToAdd.zipCode"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Numéro agence " label-for="num-depot-input">
          <b-form-input
            id="num-depot-input"
            v-model="depositToAdd.depot_nbr"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Filiale *" label-for="equipe-input">
          <b-form-select
            v-if="teamsfiliale && teamsfiliale.length"
            id="equipe-input"
            v-model="depositToAdd.team_id"
            @change="handleFilialeChange()"
            required
          >
            <option :value="null" disabled>
              -- Sélectionner Filiale --
            </option>
            <option
              v-for="item in teamsfiliale"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select>
          <b-form-select
            v-else
            id="equipe-input"
            v-model="depositToAdd.team_id"
            @change="handleFilialeChange()"
            required
          >
            <option :value="null" disabled>-- Aucun Filiale -- </option>
            <option
              v-for="item in teamsfiliale"
              :key="item.id"
              :value="item.id"
            >
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="depositToAdd.team_id"
          label="Liste des Assistance maitre d'ouvrage & Visiteurs Techniques"
          label-for="listeCommITE-input"
        >
          <div v-if="getDepotsLoadingCommercialITE" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="commercialIte && commercialIte.length">
            <b-form-checkbox
              v-for="(item, index) in commercialIte"
              :key="index"
              v-model="depositToAdd.selectedCommercialIte[index]"
            >
              <div
                :class="{
                  'icon-exclamation': item.pipe_tech_deleted === 1
                }"
                :title="
                  item.pipe_tech_deleted === 1
                    ? 'Technicien supprimé de pipedrive'
                    : ''
                "
              >
                {{ item.first_name }} {{ item.last_name }}
                <!-- <strong class="type"> ( {{ item.type | typeFormat }} ) </strong>
                <strong class="type" v-if="item.depot_id != null">
                  - ( Agence {{ item.depot.data.name }} )
                </strong> -->
              </div>
              <strong class="type" v-if="item.depot_id != null">
                ({{ item.depot.data.name }})
              </strong>
            </b-form-checkbox>
          </div>
          <div
            v-if="
              (!commercialIte || !commercialIte.length) &&
                !getDepotsLoadingCommercialITE
            "
            class="aucunTechnicien"
          >
            Aucun AMO et visiteur techniques
          </div>
        </b-form-group>
        <b-form-group
          v-if="depositToAdd.team_id"
          label="Liste des techniciens travaux"
          label-for="listertech-input"
        >
          <div v-if="getDepotsLoadingTechniciens" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="technicians && technicians.length">
            <b-form-checkbox
              v-for="(item, index) in technicians"
              :key="index"
              v-model="depositToAdd.selectedTechnicians[index]"
              required
            >
              <div
                :class="{
                  'icon-exclamation': item.pipe_tech_deleted === 1
                }"
                :title="
                  item.pipe_tech_deleted === 1
                    ? 'Technicien supprimé de pipedrive'
                    : ''
                "
              >
                {{ item.first_name }} {{ item.last_name }}
              </div>
              <strong class="type" v-if="item.depot_id != null">
                ({{ item.depot.data.name }})
              </strong>
            </b-form-checkbox>
          </div>
          <div
            v-if="
              (!technicians || !technicians.length) &&
                !getDepotsLoadingTechniciens
            "
            class="aucunTechnicien"
          >
            Aucun technicien travaux
          </div>
        </b-form-group>
        <!-- Add Deposit Modal: messages -->
        <div class="message">
          <div v-if="loading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <!-- Add Deposit Modal: buttons -->

        <div class="form-actions">
          <b-button
            @click="hideModal('addDepotsModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Add Deposit Modal -->

    <!-- Update Deposit Modal -->
    <b-modal
      no-close-on-backdrop
      id="ModalUpdate"
      ref="ModalUpdate"
      title="Editer  agence"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <form v-if="depositToUpdate" @submit.prevent="handleSubmitUpdate">
        <b-form-group label="Nom *" label-for="nom-input-update">
          <b-form-input
            id="Nom-input"
            v-model="depositToUpdate.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Code Postal *" label-for="cp-input-update">
          <b-form-input
            type="number"
            id="Code Postal-input"
            v-model="depositToUpdate.zipCode"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Numéro agence " label-for="num-depot-input">
          <b-form-input
            id="num-depot-input"
            v-model="depositToUpdate.depot_nbr"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Filiale *" label-for="equipe-input-update">
          <b-form-select
            v-if="teamsfiliale && teamsfiliale.length"
            id="equipe-input"
            v-model="depositToUpdate.team_id"
            @change="handleFilialeChangeUpdate()"
            required
          >
            <option
              v-for="item in teamsfiliale"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select>
          <b-form-select
            v-else
            id="equipe-input"
            v-model="depositToUpdate.team_id"
            @change="handleFilialeChangeUpdate()"
            required
          >
            <option :value="null" disabled>-- Aucun Filiale -- </option>
            <option
              v-for="item in teamsfiliale"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-form-group>
        <!--  -->
        <b-form-group
          label="Liste des Assistance maitre d'ouvrage & Visiteurs Techniques"
          label-for="liste-commercialITE-input-update"
        >
          <div v-if="getDepotsLoadingCommercialITE" class="three-dots-loading">
            Chargement en cours
          </div>
          <b-form-checkbox-group
            v-model="depositToUpdate.selectedCommercialIte"
            class="checkbox"
          >
            <div v-if="commercialIte && commercialIte.length">
              <b-form-checkbox
                v-for="item in commercialIte"
                :key="item.id"
                :value="item.id"
              >
                <div
                  :class="{
                    'icon-exclamation': item.pipe_tech_deleted === 1
                  }"
                  :title="
                    item.pipe_tech_deleted === 1
                      ? 'Technicien supprimé de pipedrive'
                      : ''
                  "
                >
                  {{ item.first_name }} {{ item.last_name }}
                  <!-- <strong class="type">
                    ( {{ item.type | typeFormat }} )
                  </strong>
                  <strong class="type" v-if="item.depot_id != null">
                    - ( Agence {{ item.depot.data.name }} )
                  </strong> -->
                </div>
                <strong class="type" v-if="item.depot_id != null">
                  ({{ item.depot.data.name }})
                </strong>
              </b-form-checkbox>
            </div>
            <div
              v-if="
                (!commercialIte || !commercialIte.length) &&
                  !getDepotsLoadingCommercialITE
              "
              class="aucunTechnicien"
            >
              Aucun AMO et visiteur techniques
            </div>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group
          label="Liste les techniciens travaux"
          label-for="liste-tech-input-update"
        >
          <div v-if="getDepotsLoadingTechniciens" class="three-dots-loading">
            Chargement en cours
          </div>
          <b-form-checkbox-group
            v-model="depositToUpdate.selectedTechnicians"
            class="checkbox"
          >
            <div v-if="technicians && technicians.length">
              <b-form-checkbox
                v-for="item in technicians"
                :key="item.id"
                :value="item.id"
              >
                <div
                  :class="{
                    'icon-exclamation': item.pipe_tech_deleted === 1
                  }"
                  :title="
                    item.pipe_tech_deleted === 1
                      ? 'Technicien supprimé de pipedrive'
                      : ''
                  "
                >
                  {{ item.first_name }} {{ item.last_name }}
                </div>
                <strong class="type" v-if="item.depot_id != null">
                  ({{ item.depot.data.name }})
                </strong>
              </b-form-checkbox>
            </div>
            <div
              v-if="
                (!technicians || !technicians.length) &&
                  !getDepotsLoadingTechniciens
              "
              class="aucunTechnicien"
            >
              Aucun technicien travaux
            </div>
          </b-form-checkbox-group>
        </b-form-group>
        <!--  Deposit Modal: messages -->
        <div class="message">
          <div v-if="loading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <!-- Update Deposit Modal: buttons -->
        <div class="form-actions">
          <b-button
            @click="hideModal('ModalUpdate')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Update Deposit Modal -->

    <!-- Delete Deposit Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDelete"
      id="ModalDelete"
      title=" Supprimer une agence"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer l'agence
        <strong> {{ depositToDelete ? depositToDelete.name : '' }}</strong> ?
      </p>
      <div class="message">
        <div v-if="loading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button @click="hideModal('ModalDelete')" variant="outline-secondary">
          Annuler
        </b-button>
        <b-button @click="handleModalValider" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- END Delete Deposit Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      depositToAdd: {
        name: null,
        zipCode: null,
        team_id: null,
        technicians: [],
        commercialIte: [],
        selectedTechnicians: [],
        selectedCommercialIte: [],
        depot_nbr: null
      },
      depositToUpdate: null,
      depositToDelete: null,
      loading: false,
      error: null,
      initLoading: true,
      full_name: null,
      sortDesc: false,
      sort_by: 'full_name',
      sort_by_desc: 'full_name'
    }
  },
  methods: {
    ...mapActions([
      'addDepots',
      'getteamsfiliale',
      'getDepots',
      'postlistTechniciensFilialeDepots',
      'postlistCommercialsFilialeDepots',
      'deleteDepot',
      'updateDepots'
    ]),
    async filter() {
      if (!this.sortDesc) {
        this.getDepots({
          exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
          full_name: this.full_name,
          sort_by: this.sort_by
        })
      } else {
        this.getDepots({
          exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
          full_name: this.full_name,
          sort_by_desc: this.sort_by_desc
        })
      }
    },
    async sortBy() {
      this.loading = true
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        await this.getDepots({
          exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
          full_name: this.full_name,
          sort_by: this.sort_by
        })
      } else {
        await this.getDepots({
          exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
          full_name: this.full_name,
          sort_by_desc: this.sort_by_desc
        })
      }
      this.loading = false
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    validateDepots() {
      const validate = {
        status: 'success',
        errors: []
      }
      // if (!deposit.selectedTechnicians.length) {
      //   validate.status = 'error'
      //   validate.errors.push('Le champ de techniciens est obligatoire.')
      // }
      return validate
    },
    async hundleUpdateClick(deposit) {
      const d = Object.assign({}, deposit)
      if (!d.team) {
        d.team = { data: {} }
      }
      this.depositToUpdate = d
      this.$refs['ModalUpdate'].show()
      if (this.depositToUpdate.team && this.depositToUpdate.team.data) {
        this.depositToUpdate.team_id = this.depositToUpdate.team.data.id
      }
      if (this.depositToUpdate.team.data.id) {
        this.postlistTechniciensFilialeDepots({
          team_id: this.depositToUpdate.team.data.id,
          exclude: ['team', 'technicianPipe', 'visibleTeams']
        })
        this.postlistCommercialsFilialeDepots({
          team_id: this.depositToUpdate.team.data.id,
          exclude: ['team', 'technicianPipe', 'visibleTeams']
        })
      }
      this.depositToUpdate.selectedTechnicians = this.depositToUpdate.poseurs.data.map(
        technicians => {
          return technicians.id
        }
      )
      this.depositToUpdate.selectedCommercialIte = this.depositToUpdate.commercialsIte.data.map(
        commercialIte => {
          return commercialIte.id
        }
      )
    },
    hundleDeleteClick(deposit) {
      this.depositToDelete = deposit
      this.$refs['ModalDelete'].show()
    },
    async handleModalValider() {
      this.loading = true
      this.error = null
      const response = await this.deleteDepot(this.depositToDelete.id)
      if (response.success) {
        this.loading = false
        this.getteamsfiliale({
          exclude: ['depots', 'manager', 'members']
        })
        this.hideModal('ModalDelete')
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleFilialeChange() {
      this.depositToAdd.selectedTechnicians = []
      this.postlistTechniciensFilialeDepots()
      this.postlistTechniciensFilialeDepots({
        team_id: this.depositToAdd.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
      this.depositToAdd.selectedCommercialIte = []
      this.postlistCommercialsFilialeDepots()
      this.postlistCommercialsFilialeDepots({
        team_id: this.depositToAdd.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
    },
    async handleFilialeChangeUpdate() {
      this.depositToUpdate.selectedTechnicians = []
      await this.postlistTechniciensFilialeDepots()
      this.postlistTechniciensFilialeDepots({
        team_id: this.depositToUpdate.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
      this.depositToUpdate.selectedCommercialIte = []
      this.postlistCommercialsFilialeDepots()
      await this.postlistCommercialsFilialeDepots({
        team_id: this.depositToUpdate.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
    },
    resetModal() {
      this.depositToAdd = {
        name: null,
        zipCode: null,
        team_id: null,
        technicians: [],
        commercialIte: [],
        selectedTechnicians: [],
        selectedCommercialIte: [],
        depot_nbr: null
      }
      this.depositToUpdate = null
      this.depositToDelete = null
      this.team_id = []
      this.loading = false
      this.error = null
      this.postlistTechniciensFilialeDepots()
      this.postlistCommercialsFilialeDepots()
    },
    async handleSubmit() {
      const payload = {
        name: this.depositToAdd.name,
        zipCode: this.depositToAdd.zipCode,
        depot_nbr: this.depositToAdd.depot_nbr,
        team_id: this.depositToAdd.team_id
      }
      if (this.depositToAdd.team_id) {
        const technicians = []
        for (
          let index = 0;
          index < this.depositToAdd.selectedTechnicians.length;
          index++
        ) {
          if (this.depositToAdd.selectedTechnicians[index]) {
            technicians.push(this.technicians[index].id)
          }
        }
        payload.technicians = technicians
      }
      if (this.depositToAdd.team_id) {
        const commercialIte = []
        for (
          let index = 0;
          index < this.depositToAdd.selectedCommercialIte.length;
          index++
        ) {
          if (this.depositToAdd.selectedCommercialIte[index]) {
            commercialIte.push(this.commercialIte[index].id)
          }
        }
        payload.commercialIte = commercialIte
      }
      this.loading = true
      this.error = null
      const response = await this.addDepots(payload)
      if (response.success) {
        this.loading = false
        this.resetModal()
        this.hideModal('addDepotsModal')
        this.getteamsfiliale({
          exclude: ['depots', 'manager', 'members']
        })
        if (!this.sortDesc) {
          this.getDepots({
            exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
            full_name: this.full_name,
            sort_by: this.sort_by
          })
        } else {
          this.getDepots({
            exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
            full_name: this.full_name,
            sort_by_desc: this.sort_by_desc
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleSubmitUpdate() {
      this.loading = true
      this.error = null
      const validate = this.validateDepots(this.depositToUpdate)
      if (validate.status === 'error') {
        this.error = validate.errors
        this.loading = false
        return
      }
      const response = await this.updateDepots(this.depositToUpdate)
      if (response.success) {
        this.loading = false
        this.hideModal('ModalUpdate')
        this.getteamsfiliale({
          exclude: ['depots', 'manager', 'members']
        })
        if (!this.sortDesc) {
          this.getDepots({
            exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
            full_name: this.full_name,
            sort_by: this.sort_by
          })
        } else {
          this.getDepots({
            exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
            full_name: this.full_name,
            sort_by_desc: this.sort_by_desc
          })
        }
        this.resetModal()
      } else {
        this.loading = false
        this.error = response.error
      }
    }
  },
  computed: {
    ...mapGetters([
      'deposits',
      'teamsfiliale',
      'technicians',
      'commercialIte',
      'getDepotsLoadingCommercialITE',
      'getDepotsLoadingTechniciens'
    ])
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'CERTIGAIA Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'AMO'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        case 'user.ingenieur':
          return 'Ingénieur'
        case 'user.visiteur.tech':
          return 'Visiteur Technique'
        case 'user.tech.travaux':
          return 'Technicien Travaux'
        default:
          return ''
      }
    }
  },
  async mounted() {
    await this.getDepots({
      exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers'],
      full_name: this.full_name,
      sort_by: this.sort_by
    })
    this.getteamsfiliale({
      exclude: ['depots', 'manager', 'members']
    })
    this.postlistTechniciensFilialeDepots()
    this.postlistCommercialsFilialeDepots()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.deposits-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .button {
      display: flex;
      .searchIcon {
        border-radius: unset;
        box-shadow: unset;
        cursor: pointer;
        border: 1px solid #6c757d59;
        &:focus {
          box-shadow: unset;
        }
        &.padd-btn {
          padding: 0px 9px;
        }
      }
    }
  }
  .input-search-deposite {
    // margin-top: 15px;
    // display: flex;
    // align-items: center;
    // white-space: nowrap;
    // width: 100%;
    .block-search {
      cursor: pointer;
      .search {
        border: 1px solid #dadada;
        outline: none;
        font-size: 16px;
        height: 40px;
        background: #fff;
        padding-left: 10px;
      }
    }
    .init-loading {
      margin-left: 50px;
    }
  }
  .list-group {
    .aucun-agence {
      color: #317ae2;
      text-align: center;
    }
    .list-group-item {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .gras {
          text-transform: capitalize;
          font-weight: bold;
        }
        .right {
          display: flex;
          justify-content: flex-end;

          .actions {
            display: block;
            margin-right: 20px;
            button {
              margin: 0 2px;
            }
          }
          .icon {
            display: flex;
            flex-direction: column-reverse;
            margin-right: 16px;
          }
        }
      }
      .mt-2 {
        ul {
          margin-top: 5px;
        }
      }

      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#addDepotsModal {
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .aucunTechnicien {
    color: #317ae2;
    margin-left: 35px;
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .custom-control-inline {
    display: block;
  }
}
#ModalUpdate {
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .aucunTechnicien {
    color: #317ae2;
    margin-left: 35px;
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .custom-control-inline {
    display: block;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
